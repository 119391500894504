@mixin section-heading {
  color: $section-heading;
  font-size: 1.143em;
  margin: 0 0 10px 10px;
  text-transform: capitalize;
}

@mixin title-type {
  color: $title-type-color;
  font-size: 1em;
  line-height: 1.2em;
  margin-bottom: 2px;
  word-break: break-word;
}

@mixin tile-cb {
  color: $tile-cb-color;
  font-size: 0.857em;
  text-align: center;
  word-break: break-word;
}

@mixin tile-img-card {
  background-color: #fff;
  border: 1px solid rgba(216, 216, 216, 0.5);
  border-radius: 15px;
  margin: 0 auto 10px;
  padding: 14px 10px;
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin title-img-icon {
  height: 28px;
}

@mixin input-form {
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 216, 216);
  border-radius: 10px;
  color: rgb(34, 34, 34);
  font-family: sspBold;
  font-size: 16px;
  line-height: 14px;
  padding: 14px 0px 14px 13px;
  width: 100%;
  height: 50px;
  text-transform: capitalize;
}

@mixin next-button {
  color: rgb(255, 255, 255);
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: linear-gradient(
    to right,
    rgb(246, 105, 63) 0%,
    rgb(235, 131, 69) 100%
  );
  border: none;
  border-radius: 10px;
  font-family: sspBold;
  font-size: 16px;
  height: 54px;
}

