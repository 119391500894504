@media (max-width: 1024px) {
  .main.primary-bg {
    background-color: #fff;
  }
  .main .main-container .main-navigation {
    display: none;
  }
  .more-icon {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .home-slider-section .home-slider .investment-slide-item {
    max-width: 100%;
  }
  .home-slider-section.investments-slider-section {
    padding: 0px 15px;
  }
  .main-header .header-content {
    padding: 2px 11px 2px 12px;
    margin-left: 0px;
  }
  .main-header .header-content .header-login img {
    display: none;
  }
  .model-wrapper {
    min-width: inherit !important;
    min-height: 0px !important;
  }
  .model-wrapper {
    padding: 25px 1px 15px 2px;
  }
  .main-header .header-content .header-login button {
    border-radius: 5px;
    font-family: sspRegular;
    font-size: 1em;
    padding: 7px 18px;
  }
  .jEfuYg {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    width: 100%;
    background-color: white;
    border: 1px solid #e3714d;
    border-radius: 0px;
    color: #e3714d;
    font-size: 12px;
    height: auto;
  }
  .main-container {
    padding: 10px 11px;
  }
  .css-804o3t {
    position: inherit;
  }
  .tab-checkbox .tab-check .list-item {
    width: calc(100% - 112px);
  }
}

@media (min-width: 1025px) {
  .nav-tabs-wrapper {
    margin-bottom: 0;
  }
  .main {
    min-height: 100vh;
  }
  .main-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4;
    -webkit-box-shadow: 0 0 20px 0 rgba(239, 239, 239, 0.6);
            box-shadow: 0 0 20px 0 rgba(239, 239, 239, 0.6);
  }
  .main-header .header-content {
    margin-left: auto;
    padding: 2px 10px;
  }
  .main-header .main-wrapper {
    padding: 13px 20px;
  }
  .main-container .main-wrapper {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content-wrapper,
  .main-wrapper {
    margin: 0 auto;
    max-width: 1230px;
  }
  .main-header .header-content .header-logo .logo-header {
    width: 150px;
  }
  .main-header .header-content .header-options {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
  }
  .main-header .header-content .header-login {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .main-header .header-content .header-login img {
    width: 45px;
  }
  .main-header .header-content .header-login .login-btn {
    font-size: 1.143em;
  }
  .main-container .main-navigation {
    background-color: #fff;
    border-radius: 15px;
    height: 100vh;
    max-height: calc(100vh - 145px);
    max-width: 240px;
    position: -webkit-sticky;
    position: sticky;
    top: 110px;
    width: 18%;
    -webkit-box-shadow: 0 0 20px 0 rgba(239, 239, 239, 0.6);
    box-shadow: 0 0 20px 0 rgba(239, 239, 239, 0.6);
  }
  .main-container .main-navigation .navigation-list {
    background-color: #fff;
    border-radius: 15px;
    margin: 0 auto 0 0;
    overflow: hidden;
  }
  .main-container .main-navigation .list-track {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .main-container .main-navigation .list-track .primary-list,
  .main-container .main-navigation .list-track .secondary-list {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    padding: 15px;
    width: 100%;
  }
  .main-container .main-navigation ul:first-child li:first-child {
    margin-top: 0;
  }
  .main-container .main-navigation ul li.active-option {
    background-color: #1728e70d;
    border-radius: 12px;
    color: rgba(34, 34, 34, 0.76);
    -webkit-box-shadow: 0 0 20px 0 rgba(255, 218, 202, 0.08);
    box-shadow: 0 0 20px 0 rgba(255, 218, 202, 0.08);
  }
  .main-container .main-navigation ul li {
    margin: 10px 0;
    padding: 15px 20px;
  }
  .main-container .main-navigation ul li img {
    width: 20px;
  }
  .main-container .main-navigation ul li.active-option .label-text {
    font-family: sspBold;
  }
  .main-container .main-navigation ul li.list-divider {
    border-bottom: 1px dashed #e5e4e4;
    margin: 20px 0;
    padding: 0;
  }
  .main-container .main-navigation ul:last-child li:last-child {
    margin-bottom: 0;
  }
  .main-container .main-wrapper .main-content.w-80 {
    width: 100%;
  }
  .main .main-container .main-wrapper .main-content.desktop-view-pending {
    background-color: #fff;
    border-radius: 20px;
    margin-right: 0;
    max-width: 450px;
    min-width: 450px;
    padding: 10px;
    -webkit-box-shadow: 0 0 20px 0 #efefef99;
            box-shadow: 0 0 20px 0 #efefef99;
  }
  .main-container .main-wrapper > :only-child.main-content {
    width: 100%;
  }
  .main-container .main-content {
    margin: 0 auto;
    width: 100%;
  }
  .main-content.rent-card {
    width: 50%;
    margin: 0 25px;
  }
  .fixed-wrap-card {
    width: 50%;
    margin-right: 25px;
  }
  .home-tab-wrapper .grid-section:first-child {
    border-radius: 0;
    background: rgba(244, 246, 247, 0.25);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(5%, rgba(244, 246, 247, 0.25)), color-stop(50%, #f4f6f7));
    background: linear-gradient(180deg, rgba(244, 246, 247, 0.25) 5%, #f4f6f7 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
  }
  .home-tab-wrapper .grid-section {
    margin-bottom: 25px;
    margin-right: 15px;
    padding: 0;
  }
  .main-container .main-aside {
    height: 100vh;
    max-height: calc(100vh - 145px);
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: 110px;
    width: 20%;
  }
  .home-slider-section
.home-slider
.investment-slide-item
.lower-section
.investment-sub-title {
    text-align: left;
  }
  .footer-section .lower-section .text-content {
    color: #444;
    font-family: sspRegular;
    font-size: 1em;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 20px 0;
  }
  .add-landlord-details-section .add-landlord-details-form,
  .modify-landlord-details-section .modify-landlord-details-form,
  .saved-landlord-details-section.add-btn-present {
    margin-bottom: 0;
  }
  .payment-section-wrapper {
    background-color: inherit;
  }
  .payment-section-wrapper .pay-option-form {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .payment-section-wrapper .first-col {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(65% - 25px);
            flex: 0 0 calc(65% - 25px);
    margin-right: 25px;
    max-width: calc(65% - 25px);
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .payment-section-wrapper .payment-options-section {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 20px;
    -webkit-box-shadow: 0 0 23px 0 rgba(235, 235, 235, 0.53);
    box-shadow: 0 0 23px 0 rgba(235, 235, 235, 0.53);
  }
  .payment-section-wrapper .second-col {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 35%;
            flex: 0 0 35%;
    max-width: 35%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .payment-section-wrapper .payment-head-section {
    border-radius: 20px;
    border-top: 1px solid #eaeaea;
    margin-bottom: 20px;
    padding: 30px 36px 20px;
    -webkit-box-shadow: 0 0 23px 0 rgba(235, 235, 235, 0.53);
    box-shadow: 0 0 23px 0 rgba(235, 235, 235, 0.53);
  }
  .payment-section-wrapper .payment-head-section .payment-details-section {
    margin-bottom: 0;
  }
  .payment-section-wrapper
.payment-head-section
.payment-details-section
.payment-details-data {
    margin-bottom: 0;
  }
  .payment-section-wrapper
.payment-head-section
.payment-details-section
.payment-details-data
.payment-details-first-row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 26px;
  }
  .payment-section-wrapper
.payment-head-section
.payment-details-section
.payment-details-data
.payment-details-first-row
.action {
    height: 35px;
  }
  .payment-section-wrapper .payment-head-section .payment-details-bbps {
    top: 30px;
  }
  .promo-code-section {
    margin-bottom: 60px;
    position: relative;
  }
  .promo-code-section input {
    height: 50px;
  }
  .promo-code-section .input-container img {
    top: 20px;
  }
  .promo-code-section .input-container p {
    top: 18px;
  }
  .promo-code-section .dashed-line {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, transparent), color-stop(0, #e1d9d9));
    background-image: linear-gradient(90deg, transparent 50%, #e1d9d9 0);
    background-size: 10px 100%;
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    top: 80px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .main-container {
    background: #fbfbfb;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(75%, #fbfbfb), to(#fff));
    background: linear-gradient(180deg, #fbfbfb 75%, #fff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
  }
  .main-container {
    padding: 35px 10px;
  }
  .main-container .main-navigation ul li.active-option .label-text {
    color: #1728e7;
  }
  .home-tab-wrapper .grid-section:first-child {
    background: transparent;
  }
  .home-tab-wrapper .grid-section .section-tiles-wrapper {
    -ms-grid-columns: (95px)[6];
        grid-template-columns: repeat(6, 95px);
  }
  .home-slider-section.exclusive-deals-slider-section,
  .home-slider-section.home-notifications,
  .home-slider-section.investments-slider-section,
  .home-slider-section.spotlight-slider-section {
    background-color: transparent;
  }
  .home-slider-section {
    padding: 10px 20px 5px;
  }
  .home-slider-section .home-slider .investment-slide-item .lower-section {
    padding: 20px;
  }
  .form-section .input-fields {
    margin-bottom: 75px;
  }
  .categorydetails-page,
  .locationdetails-page {
    background: #fff;
    border-radius: 12px;
    -webkit-box-shadow: 0 0 20px 0 #efefef61;
            box-shadow: 0 0 20px 0 #efefef61;
    margin-bottom: 20px;
    padding: 20px 34px 0;
  }
  .categorydetails-page form,
  .locationdetails-page form {
    max-width: 400px;
    padding: 15px 0 40px;
  }
  .categorydetails-page form .button-wrapper .form-submit-btn,
  .locationdetails-page form .button-wrapper .form-submit-btn {
    -webkit-box-shadow: 0 6px 14px 0 #ff774480;
            box-shadow: 0 6px 14px 0 #ff774480;
    margin-top: 15px;
    max-width: 200px;
  }
  .about-section {
    margin: 35px 0 15px;
    padding: 25px;
  }
  .footer-section .upper-section .social-links {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -ms-grid;
    display: grid;
    grid-column-gap: 20px;
    -ms-grid-columns: 28% auto 38%;
        grid-template-columns: 28% auto 38%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 30px;
  }
  .footer-section .upper-section .social-links h3 {
    display: none;
  }
  .footer-section .upper-section .social-links .social-medias-links {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .footer-section .upper-section .social-links .app-links,
  .footer-section .upper-section .social-links .social-medias-links {
    margin-bottom: 0;
  }
  .footer-section
.upper-section
.social-links
.social-medias-links
.images-list {
    margin-top: 0;
  }
  .footer-section .upper-section .social-links .app-links {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .footer-section .upper-section .social-links .app-links,
  .footer-section .upper-section .social-links .social-medias-links {
    margin-bottom: 0;
  }
  .footer-section .upper-section .social-links h3 {
    display: none;
  }
  .footer-section .upper-section .social-links .app-links .images-list {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    display: -ms-grid;
    display: grid;
    grid-column-gap: 10px;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  .footer-section
.upper-section
.social-links
.app-links
.images-list
.app-store,
  .footer-section
.upper-section
.social-links
.app-links
.images-list
.google-play {
    width: 100%;
  }
  .footer-section .upper-section .social-links .certified-image {
    border: none;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    padding-top: 0;
  }
  .add-landlord-details-form .form-fields,
  .modify-landlord-details-form .form-fields,
  .services-details-form .form-fields {
    margin-bottom: 35px;
  }
  .form-btns.fixed-form-btn {
    margin: 0 -20px;
    padding-bottom: 0;
    position: -webkit-sticky;
    position: sticky;
  }
  .search-layout.new-layout .search-layout-field-wrapper {
    background: #fff;
    max-width: 770px;
  }
  .offers-slider-wrapper {
    margin: 18px 0 25px;
  }
  .search-layout.new-layout .search-layout-section .slider .slide {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .offers-slider-wrapper .offers-slider .slide {
    -ms-grid-columns: calc(100% - 10px);
        grid-template-columns: calc(100% - 10px);
  }
  .offers-card {
    -webkit-box-shadow: 0 0 9px 0 RGB(0 0 0/5%);
            box-shadow: 0 0 9px 0 RGB(0 0 0/5%);
    border: 1px solid #ddd;
  }
  .location-info-box {
    margin: 14px 0 10px;
  }
  .search-layout.new-layout .search-layout-section .section-head {
    border-bottom: 1px solid rgba(219, 219, 219, 0.64);
    margin: 25px 0 20px;
    padding-bottom: 15px;
  }
  .search-layout.new-layout .search-layout-section .group-head {
    font-size: 1em;
    margin: 10px 0;
  }
  .search-layout.new-layout .operator-tile {
    -webkit-box-shadow: 0 0 9px 0 RGB(0 0 0/5%);
            box-shadow: 0 0 9px 0 RGB(0 0 0/5%);
    margin-right: 20px;
    border: 1px solid #ddd;
  }
  .search-layout.new-layout .operator-tile:last-of-type {
    margin-right: 0;
  }
  .providerdetails-page,
  .reviewbill-page {
    -webkit-box-shadow: 0 0 20px 0 #efefef99;
            box-shadow: 0 0 20px 0 #efefef99;
  }
  .providerdetails-page {
    background: #fff;
    border-radius: 12px;
  }
  .providerdetails-page form {
    max-width: 400px;
  }
  .providerdetails-page form .accountdetails-head {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .providerdetails-page form .button-wrapper .form-submit-btn {
    margin-top: 15px;
    max-width: 200px;
  }
  .search-layout.new-layout .all-providers-tile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .gplay-seo-container .gplay-container {
    min-height: auto;
  }
  .gplay-container.new-layout
.amount-layout-section.amount-input-wrapper
.amount-layout-flex-wrapper
.amount-input-field-wrapper {
    max-width: 400px;
  }
  .gplay-container.new-layout
.amount-layout-section.amount-input-wrapper
.amt-label {
    color: #222;
    font-family: sspBold;
    font-size: 1em;
    padding-bottom: 10px;
  }
  .gplay-container.new-layout .button-wrapper {
    margin: 28px 10px 10px 17px;
  }
  .gplay-container.new-layout .button-wrapper .form-submit-btn {
    -webkit-box-shadow: 0 6px 14px 0rgba 255, 119, 68, 0.5;
            box-shadow: 0 6px 14px 0rgba 255, 119, 68, 0.5;
    max-width: 200px;
    padding: 16px 62px 19px 61px;
  }
  .gplay-container .choose-amt-div .rounded-list .list-item {
    margin-bottom: 10px;
    width: 100px;
  }
  .gplay-container .choose-amt-div .rounded-list .list-item p {
    font-size: 1.143em;
  }
  .gplay-container .benefits-container .benefits-div .benefits-label {
    text-transform: uppercase;
  }
  .gplay-container .benefits-container .benefits-div .benefits-div-grid {
    display: -ms-grid;
    display: grid;
    gap: 15px;
    -ms-grid-columns: (calc(33.33% - 15px))[3];
        grid-template-columns: repeat(3, calc(33.33% - 15px));
  }
  .gplay-container
.benefits-container
.benefits-div
.benefits-div-grid
.benefit-item {
    border: 1px solid #e1ebff;
    border-radius: 12px;
    margin: 40px 0 20px;
    padding: 25px 30px;
  }
  .gplay-container
.benefits-container
.benefits-div
.benefits-div-grid
.benefit-item
img {
    height: 60px;
    width: 75px;
  }
  .gplay-container
.benefits-container
.benefits-div
.benefits-div-grid
.benefit-item
.benefit-text-section
.benefit-head {
    margin-top: 15px;
  }
  .gplay-container
.benefits-container
.benefits-div
.benefits-div-grid
.benefit-item
.benefit-text-section
.benefit-text {
    margin-top: 6px;
  }
  .gplay-container .tnc-container .tnc-div .tnc-label {
    line-height: 18px;
    text-transform: uppercase;
  }
  .use-logic-only {
    max-width: 433px;
  }
  .billdetails-page {
    border-radius: 12px;
    overflow: hidden;
  }
  .billdetails-page .billdetails-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
  }
  .billdetails-page .billdetails-wrapper .billdetails-head {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    border: none;
    -webkit-box-shadow: 0 0 20px 0 rgba(239, 239, 239, 0.38);
            box-shadow: 0 0 20px 0 rgba(239, 239, 239, 0.38);
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin: 0;
    padding: 40px 25px 40px 30px;
    width: 35%;
  }
  .billdetails-page .billdetails-wrapper .bill-details-content {
    padding: 35px 30px 35px 35px;
    width: 65%;
  }
  .billdetails-page
.billdetails-wrapper
.bill-details-content
.bill-details-head-wrapper {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    padding-bottom: 10px;
  }
  .billdetails-page
.billdetails-wrapper
.bill-details-content
.bill-fields-area {
    border: 1px solid #e1ebff;
    -webkit-box-shadow: 0 5px 10px 0 rgba(233, 233, 233, 0.4);
            box-shadow: 0 5px 10px 0 rgba(233, 233, 233, 0.4);
  }
  .billdetails-page .billdetails-wrapper .bill-field {
    margin-bottom: 22px;
  }
  .billdetails-page .footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 20px 0;
  }
  .billdetails-page .proceed-btn:not([disabled]) {
    -webkit-box-shadow: 0 6px 14px 0 RGBA(255 119 68/50%);
            box-shadow: 0 6px 14px 0 RGBA(255 119 68/50%);
  }
  .pop-up-wrapper.show-pop-up {
    opacity: 1;
    z-index: 4;
  }
  .pop-up-wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    bottom: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    opacity: 0;
    top: 0;
    z-index: -1;
    -webkit-transition: opacity 0.25s ease-in-out 0s;
    transition: opacity 0.25s ease-in-out 0s;
  }
  .pop-up-wrapper.top-rounded-layout .pop-up {
    border-radius: 20px;
    overflow-y: hidden;
  }
  .pop-up-wrapper.show-pop-up .pop-up {
    border-radius: 20px;
    width: 500px;
  }
  .vertical-tab-container {
    border-radius: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-shadow: 0 0 9px 0 0 0 15px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 9px 0 0 0 15px 0 rgba(0, 0, 0, 0.06);
  }
  .vertical-tab-container .tabs-collection {
    background: #fbfbfb;
    border-right: 1px solid #f0f4f5;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 50px;
    width: 30%;
  }
  .vertical-tab-container .tabs-collection {
    overflow: visible;
  }
  .vertical-tab-container .tabs-collection .tab-collection-header.title {
    color: #222;
    font-family: "Roboto", sans-serif;
    font-size: 1.286em;
    padding: 24px 34px;
    text-align: start;
  }
  .vertical-tab-container .tab-collection-header {
    border-bottom: 1px solid #f0f4f5;
    color: #222;
    font-family: "Roboto", sans-serif;
    font-size: 1.286em;
    padding: 35px 25px 25px;
    text-align: center;
  }
  .vertical-tab-container .tabs-collection .tab-option.active {
    background: #fff;
    border-left-color: #e3714e;
    position: relative;
  }
  .vertical-tab-container .tabs-collection .tab-option {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 0;
    border-left: 3px solid transparent;
    color: #222;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.143em;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    line-height: 1.2;
    margin-right: -2px;
    padding: 20px 25px;
    text-align: left;
    white-space: normal;
    z-index: 2;
  }
  .vertical-tab-container .tabs-collection .tab-option.active:before {
    border-right: 3px solid #fff;
    bottom: 0;
    content: "";
    position: absolute;
    right: -1px;
    top: 0;
  }
  .vertical-tab-container .tabs-collection .tab-option .tab-icon {
    display: inline-block;
    margin-right: 13px;
  }
  .vertical-tab-container .tabs-collection .tab-option .tab-icon img {
    max-width: 100%;
  }
  .vertical-tab-container .tab-content {
    background: #fff;
    padding: 35px 35px 88px;
    width: 70%;
  }
}

@media (min-width: 1025px) {
  .home-slider-section.investments-slider-section {
    margin-bottom: 20px;
    padding: 15px 0;
  }
  .home-slider-section {
    margin: 30px 0;
    padding: 0;
  }
  .home-slider-section .section-heading {
    margin-bottom: 12px;
    margin-left: 0;
  }
  .home-slider-section .home-slider .investment-slide-item .lower-section {
    width: 75%;
  }
  .about-section {
    padding: 15px;
  }
  .footer-section .upper-section {
    background-color: #fafafa;
    padding-bottom: 25px;
  }
  .footer-section .footer-content,
  .footer-section .text-content {
    margin: 0 auto;
    max-width: 992px;
  }
  .footer-section .upper-section .footer-list-container .footer-list {
    width: 22%;
  }
  .footer-section .upper-section .footer-list-container .footer-list h3 {
    margin-bottom: 10px;
  }
  .footer-section .upper-section .footer-list-container .footer-list h3 {
    margin-bottom: 10px;
  }
  .footer-section .upper-section .social-links {
    -ms-grid-columns: 28% auto 40%;
        grid-template-columns: 28% auto 40%;
  }
  .footer-section .lower-section {
    background-color: rgba(240, 242, 243, 0.65);
  }
  .footer-section .footer-content,
  .footer-section .text-content {
    margin: 0 auto;
    max-width: 992px;
  }
  .main
.main-container
.main-wrapper
.view-pending-section
.view-pending-container-new {
    background-color: #fff;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    margin: 20px 0;
    padding: 10px;
  }
  .main
.main-container
.main-wrapper
.view-pending-section
.view-pending-container-new
img {
    width: 100%;
  }
  .search-layout.new-layout .operator-tile {
    width: calc(33.33% - 20px);
  }
  .billdetails-page .proceed-btn {
    width: 250px;
  }
  .payment-modes .payment-options-container.tabs-collection .tab-option {
    font-size: 1.143em;
    padding-bottom: 20px;
  }
  .payment-modes .vertical-tab-container .tab-content {
    position: relative;
  }
  .payment-modes .debit-credit-card-section .label-text {
    font-family: sspBold;
    font-size: 1em;
  }
  .payment-modes .debit-credit-card-section .submit-payment-option-btn {
    margin-top: 41px;
    max-width: 256px;
  }
  .payment-modes .net-banking-section .submit-payment-option-btn {
    margin-top: 35px;
    max-width: 256px;
  }
  .payment-modes .options-disabled-info {
    bottom: 10px;
    margin: 15px 0;
    position: absolute;
  }
  .payment-modes .net-banking-section {
    margin-top: 0;
  }
  .payment-modes .net-banking-section .netbanking-container > label {
    margin-bottom: 20px;
  }
  .payment-modes .net-banking-section .net-banking-view-all {
    text-align: left;
  }
}

@media (min-width: 1100px) {
  .fixed-wrap-card .fixed-card-content .fixed-header .left {
    width: 36px;
    height: 36px;
  }
  .fixed-card-content .fixed-header .right p {
    font-size: 16px;
  }
  .css-804o3t {
    position: relative;
    border-radius: 0px 0px 10px 10px;
  }
  .css-111px30 {
    padding-bottom: 110px;
    height: calc(100vh - 161px);
    max-height: calc(614px);
    overflow: auto;
  }
  .css-g9xpsd .options-wrap {
    max-width: 100%;
  }
  .css-111px30 .sel-cont > .options-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .css-111px30 .sel-cont > .options-wrap > .option {
    min-width: 102px;
    min-height: 60px;
    margin: 0px 20px 20px 0px;
  }
  .css-111px30 .sel-cont > .options-wrap > .option:last-child {
    margin-bottom: 5px;
  }
  .css-gbfk11 {
    position: absolute;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
}

@media (max-width: 767px) {
  .footer-section .upper-section .social-links .google-play {
    width: 135px;
  }
  .footer-section .upper-section .social-links .app-store {
    width: 120px;
  }
  .footer-section .lower-section .text-content {
    text-align: center;
  }
  .section-tiles-wrapper.height {
    height: 100% !important;
  }
  .main-container .main-content.color-banner, .desktop-view-pending {
    background: #fff;
    border-radius: 15px;
    padding-top: 14px;
    padding-bottom: 15px;
  }
  .categorydetails-page,
  .locationdetails-page {
    background-color: #fff;
    border-radius: 20px;
    margin-bottom: 14px;
  }
  .desktop-header-text,
  .desktop-heading-image {
    display: none;
  }
  .categorydetails-page .desktop-operators-main-header img:not(.heading-image),
  .locationdetails-page .desktop-operators-main-header img:not(.heading-image) {
    display: none;
  }
  .main-container {
    padding: 0px 8px;
    background: inherit !important;
  }
  .home-tab-wrapper {
    border: 1px solid #dddddd73;
  }
  .about-section {
    padding: 15px 15px;
  }
  .view-pending-section {
    display: none;
  }
  .search-layout.new-layout .desktop-operators-main-header img:not(.heading-image) {
    display: none;
  }
  .search-layout.new-layout .search-layout-section.search-input-wrapper {
    padding: 0px 0px 0px 0;
  }
  .providerdetails-page .desktop-operators-main-header img.bbps-img {
    display: none;
  }
  .providerdetails-page .desktop-operators-main-header img.illustrator {
    display: none;
  }
  .providerdetails-page .desktop-operators-main-header {
    display: none;
  }
  .payment-section-wrapper {
    background-color: inherit;
  }
  .payment-section-wrapper .first-col {
    margin-top: 30px;
    background-color: #fff;
    padding: 15px;
    border-radius: 15px;
    border: 1px solid #eaeaea;
  }
  .tab-collection-header {
    display: none;
  }
  .payment-section-wrapper .payment-head-section {
    margin-bottom: 30px;
  }
  .pop-up-wrapper .pop-up {
    margin-bottom: 71px;
  }
  .billdetails-page .billdetails-wrapper .bill-details-content {
    padding: 0px;
  }
  .billdetails-page .footer-content {
    padding: 0px;
  }
}
