* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background-color: #f2f5fc;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ol,
ul,
li,
figure {
  padding: 0;
  margin: 0;
  list-style: none;
}

.gjZEHA:focus,
.gjZEHA:focus-visible {
  background-color: #ffffff !important;
  border: 1px solid #ec7f51 !important;
}

input:focus-visible,
input:focus {
  outline: none;
  border: none;
}

.jEfuYg {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  background-color: white;
  border: 1px solid #e3714d;
  border-radius: 0px;
  color: #e3714d;
  font-size: 12px;
  height: auto;
}

.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 62px;
  position: relative;
}

.container,
.main-wrapper {
  width: 100%;
}

.main-header .header-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: 20px;
  padding: 2px 28px 2px 38px;
}

.main-header .header-content .header-logo {
  cursor: pointer;
}

.main-header .header-content .header-logo .logo-header {
  width: 130px;
}

.main-header .header-content .header-login img {
  width: 38px;
}

.main-header .header-content .header-login .login-btn {
  color: #e3714d;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  margin-left: 10px;
  text-transform: capitalize;
}

.main-container {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.main-container .main-navigation {
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 3;
}

.main-container .main-navigation .navigation-list {
  border: 1px solid #fff;
  height: 100%;
}

.main-container .main-navigation ul li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5e5e5e;
  cursor: pointer;
  display: -ms-grid;
  display: grid;
  grid-column-gap: 20px;
  -ms-grid-columns: (auto) [2];
  grid-template-columns: repeat(2, auto);
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 16px 10px 16px 0;
  box-pack: start;
}

.main-container .main-navigation ul li > img {
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  width: 18px;
}

.main-container .main-navigation ul li .label-text {
  color: #5a6876;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 18px;
  text-decoration: none;
}

.main-container .main-navigation ul li.list-divider {
  border-bottom: 1px dashed #e5e4e4;
  margin: 20px 0;
  padding: 0;
}

.main-container .main-navigation .back-label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px dashed #e5e4e4;
  color: #5a6876;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  margin: 0 15px;
  padding: 15px 5px 25px;
  text-transform: capitalize;
}

.main-container .main-navigation .back-label img {
  margin-right: 15px;
  width: 20px;
}

.main-container .main-navigation h6 {
  color: #5a6876;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  margin: 20px;
  text-transform: uppercase;
}

.grid-section {
  background-color: #f4f6f7;
  border-radius: 30px;
  padding: 25px;
}

.grid-section .section-heading {
  color: #222;
  font-size: 1.143em;
  margin: 0 0 10px 10px;
  text-transform: capitalize;
}

.grid-section .section-tiles-wrapper {
  display: -ms-grid;
  display: grid;
  grid-row-gap: 30px;
  -ms-grid-columns: (80px) [3];
  grid-template-columns: repeat(3, 80px);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow: hidden;
  padding-top: 5px;
  text-align: center;
  -webkit-transition: all 0.25s ease-in-out 0s;
  transition: all 0.25s ease-in-out 0s;
}

.grid-section .section-tiles-wrapper .more-icon {
  cursor: pointer;
}

.grid-section .section-tiles-wrapper .tile-img-tag,
.grid-section .section-tiles-wrapper .section-category-tile .tile-img-tag {
  background-color: #fff;
  border: 1px solid rgba(216, 216, 216, 0.5);
  border-radius: 15px;
  margin: 0 auto 10px;
  padding: 14px 10px;
  position: relative;
  width: 60px;
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-section .section-tiles-wrapper .tile-type,
.grid-section .section-tiles-wrapper .section-category-tile .tile-type {
  color: #5a6876;
  font-size: 1em;
  line-height: 1.2em;
  margin-bottom: 2px;
  word-break: break-word;
}

.grid-section .section-tiles-wrapper .section-category-tile {
  cursor: pointer;
}

.grid-section .section-tiles-wrapper .more-icon .tile-img-tag.expand {
  height: 60px;
}

.grid-section .section-tiles-wrapper .more-icon .tile-img-tag.expand span {
  top: calc(50% - 4px);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.grid-section .section-tiles-wrapper .more-icon .tile-img-tag span {
  border-bottom: 2px solid #1728e7;
  border-radius: 2px;
  border-right: 2px solid #1728e7;
  height: 12px;
  left: calc(50% - 6px);
  position: absolute;
  top: calc(50% - 8px);
  width: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: transform 0.5s ease-in-out 0s;
  -webkit-transition: -webkit-transform 0.5s ease-in-out 0s;
  transition: -webkit-transform 0.5s ease-in-out 0s;
  transition: transform 0.5s ease-in-out 0s;
  transition: transform 0.5s ease-in-out 0s,
    -webkit-transform 0.5s ease-in-out 0s;
}

.home-tab-wrapper
  .grid-section
  .section-tiles-wrapper
  .section-category-tile
  .tile-img {
  height: 28px;
}

.grid-section .section-tiles-wrapper .section-category-tile .tile-cb {
  color: #fa5451;
  font-size: 0.857em;
  text-align: center;
  word-break: break-word;
}

.home-tab-wrapper .grid-section:first-child {
  border-radius: 0;
  background: rgba(244, 246, 247, 0.25);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(5%, rgba(244, 246, 247, 0.25)),
    color-stop(50%, #f4f6f7)
  );
  background: linear-gradient(
    180deg,
    rgba(244, 246, 247, 0.25) 5%,
    #f4f6f7 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
}

.home-slider-section.investments-slider-section {
  background-color: #f4f6f7;
  padding: 15px 5px 15px 15px;
}

.home-slider-section {
  padding: 10px 5px 10px 10px;
}

.home-slider-section.investments-slider-section .section-heading {
  margin-left: 10px;
}

.home-slider-section .section-heading {
  color: #222;
  font-size: 1.143em;
  margin: 0 0 10px 10px;
  text-transform: capitalize;
}

.home-slider-section .home-slider.slider-wrapper.slider-arrows {
  padding: 0;
}

.home-slider-section .home-slider.slider-wrapper {
  margin: 0;
}

.slider-wrapper .slider-track .slider-section .slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  scrollbar-width: none;
  width: 100%;
  overflow: hidden;
}

.home-slider-section .home-slider .investment-slide-item {
  border-radius: 20px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100% - 10px);
  margin-bottom: 10px;
  max-width: 100%;
}

.bg-color-1 {
  background-color: #85acaf;
  -webkit-box-shadow: 0 3px 10px 0 #85acaf;
  box-shadow: 0 3px 10px 0 #85acaf;
}

.bg-color-2 {
  background-color: #90a5ba;
  -webkit-box-shadow: 0 3px 10px 0 #90a5ba;
  box-shadow: 0 3px 10px 0 #90a5ba;
}

.bg-color-3 {
  background-color: #6c8a94;
  -webkit-box-shadow: 0 3px 10px 0 #6c8a94;
  box-shadow: 0 3px 10px 0 #6c8a94;
}

.home-slider-section .home-slider .investment-slide-item .upper-section {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 16px 11px;
}

.home-slider-section
  .home-slider
  .investment-slide-item
  .upper-section
  .investment-image {
  margin-right: 10px;
  width: 40px;
}

.home-slider-section
  .home-slider
  .investment-slide-item
  .upper-section
  .investment-title {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 18px;
}

.home-slider-section .home-slider .investment-slide-item .lower-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 13px;
}

.home-slider-section
  .home-slider
  .investment-slide-item
  .lower-section
  .investment-sub-title {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 16px;
  max-height: 32px;
  overflow: hidden;
  text-align: center;
}

.home-slider-section .home-slider .investment-slide-item .lower-section button {
  border: none;
  border-radius: 15px;
  color: #222;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  margin-top: 20px;
  padding: 7px 10px 8px;
}

.home-slider-section .home-slider .owl-stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.section-tiles-wrapper {
  height: 258px;
}

.section-tiles-wrapper.height {
  height: 518px !important;
}

.height .setOrder {
  -webkit-box-ordinal-group: 21 !important;
  -ms-flex-order: 20 !important;
  order: 20 !important;
}

.grid-section
  .section-tiles-wrapper.height
  .more-icon
  .tile-img-tag.expand
  span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.grid-section .section-tiles-wrapper.height .more-cn {
  display: none;
}

.grid-section .section-tiles-wrapper .more-cn {
  display: block;
}

.grid-section .section-tiles-wrapper .less-cn {
  display: none;
}

.grid-section .section-tiles-wrapper.height .less-cn {
  display: block;
}

.slider-one .owl-nav {
  display: none;
}

.tile-img-tag i {
  color: #1728e7;
  font-size: 29px;
}

.card-content-rent .heading .section-heading {
  color: #222;
  font-size: 1.143em;
  margin: 0 0 10px 10px;
  text-transform: capitalize;
  margin: 0;
}

.card-content-rent .heading p {
  font-size: 12px;
  color: #999999;
  font-weight: 400;
  line-height: 16px;
  margin-top: 5px;
}

.tab-checkbox .tab-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.tab-checkbox .tab-check .list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: calc(100% - 251px);
  color: #999999;
  font-size: 15px;
}

.tab-checkbox .tab-check .list-ul li.active {
  color: #000;
}

.rent-payment-card-box {
  padding: 20px 40px 40px;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 15px;
}

.card-content-rent .tab-checkbox {
  margin-top: 45px;
}

.Tabcontent {
  display: none;
  -webkit-transition: all ease-in-out 0.6s;
  transition: all ease-in-out 0.6s;
}

.Tabcontent.active {
  display: block;
  -webkit-transition: all ease-in-out 0.6s;
  transition: all ease-in-out 0.6s;
}

.Tabcontent .tab-form-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.Tabcontent .tab-form-list ul li .tab-form {
  border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  font-size: 15px;
  font-weight: 400;
  color: #999999;
  padding: 10px 10px 10px 5px;
  border-bottom: 1px solid #ebebeb !important;
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-top: 22px;
}

.Tabcontent .tab-form-list ul li button {
  background: #fff;
  color: #00baf2;
  font-size: 16px;
  height: 36px;
  width: auto;
  border-radius: 96px;
  font-weight: 500;
  padding: 0 12px;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 1px solid #00b8f5;
  cursor: pointer;
}

.tab-form-list li:nth-child(2) {
  position: relative;
}

.tab-form-list li:nth-child(4) {
  margin-bottom: 8px;
}

.tab-form-list li a {
  position: absolute;
  right: 11px;
  top: 40px;
  font-size: 11px;
  color: #00baf2;
  text-decoration: none;
}

.form-control:focus {
  border: inherit;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  outline: inherit;
}

.required {
  font-size: 12px;
  padding-top: 1px;
  color: #6fcf97;
}

.button-text {
  font-size: 12px;
  color: #333333;
  font-weight: 400;
  margin-bottom: 12px;
}

.description-upi {
  padding: 16px 16px 11px 16px;
  background-color: #f5f9fe;
  border-radius: 8px;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  line-height: 16px;
}

.description-upi p .note {
  color: #ff9d00;
  margin-right: 5px;
}

.proceed-btn button {
  font-size: 16px;
  padding: 15px 20px;
  background: #00baf2;
  color: #fff;
  width: 100%;
  border-radius: 6px;
  border: inherit;
  text-align: center;
  border-width: 0;
  font-weight: 600;
  border: inherit;
}

.model-wrapper {
  background-color: #fff;
  padding: 25px 25px 0px 0px;
  border-radius: 20px;
}

.modal-dialog {
  pointer-events: inherit !important;
}

.form-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.page-heading {
  color: #444;
  font-family: "Roboto", sans-serif;
  font-size: 1.429em;
  margin: 0 6px 7px;
  font-weight: 600;
}

.text-content {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 21px;
  margin: 10px 6px 40px;
}

.text-content span {
  color: #1728e7;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.close-popup-icon-wrapper {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 999;
}

.form-section .input-fields .input-wrapper {
  margin-bottom: 20px;
}

.form-section .input-fields .input-container p {
  line-height: 20px;
  text-transform: capitalize;
}

.error-head {
  color: red;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0px 0px 6px 10px;
}

.categorydetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .accountdetails-field
  input,
.locationdetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .accountdetails-field
  input {
  background: #fff;
  font-size: 1.286em;
  height: 50px;
  text-transform: capitalize;
}

.iUhaYg {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f9f9fc;
  border-width: 1px;
  border-style: solid;
  border-color: #e3714d;
  border-radius: 10px;
  color: #222222;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 14px;
  padding: 14px 0px 14px 13px;
  width: 100%;
}

.iUhaYg:focus,
.iUhaYg:focus-visible {
  border-width: 1px;
  border-style: solid;
  border-color: #e3714d;
}

.error-bottom {
  color: red;
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0px;
  text-transform: capitalize;
}

.submit-btn {
  border-radius: 10px;
  margin-top: 10px;
  min-height: 48px;
  text-transform: uppercase;
  z-index: 2;
}

.submit-btn:focus {
  outline: none;
  border: inherit;
}

.gXIzzX {
  color: white;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: #aeaeae;
  border: none;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  height: 42px;
}

.fixed-wrap-card {
  background-color: #fff;
  border-radius: 15px;
}

.fixed-card-content .fixed-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 24px 24px 16px 24px;
}

.fixed-wrap-card .fixed-card-content .fixed-header .left {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #e9eaf0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 50%;
}

.fixed-wrap-card .fixed-card-content .fixed-header .right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 16px;
}

.fixed-card-content .fixed-header .right span {
  font-size: 12px;
  line-height: 16px;
  color: #999;
}

.fixed-card-content .fixed-header .right p {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-top: 6px;
}

.css-804o3t {
  background-color: white;
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  min-height: auto;
  height: calc(100% - 314px);
  border-radius: 30px;
}

.css-111px30 {
  padding: 15px 24px 70px;
}

.css-4ejps8 {
  display: block;
}

.css-1ascwlj {
  margin: 20px 0px 0px -5px;
}

.css-szbi6c {
  font-size: 9px;
  font-weight: 500;
  padding-left: 5px;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.9);
}

.css-g9xpsd .sel-placeholder {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  font-weight: 400;
  margin-bottom: 12px;
}

.css-g9xpsd .options-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.css-111px30 .sel-cont > .options-wrap {
  -ms-flex-wrap: unset;
  flex-wrap: unset;
  overflow: auto;
}

.css-111px30 .sel-cont > .options-wrap > .option {
  min-width: 95px;
  white-space: unset;
  word-break: unset;
  line-height: unset;
  min-height: 76px;
  padding: 6px 16px;
  margin: 4px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.css-g9xpsd .option {
  height: 55px;
  padding: 10px 24px;
  font-size: 14px;
  color: #222222;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
  cursor: pointer;
}

.css-111px30 .sel-cont > .options-wrap > .option > svg {
  margin: 0px 0px 7px;
}

.css-a802sj {
  margin-top: 30px;
}

.css-2jfdz5 {
  position: relative;
  display: block;
  margin-top: 20px;
  letter-spacing: 0.2px;
}

.css-2jfdz5 .inputStyle {
  font-size: 18px;
  font-weight: 500;
  color: #222222;
  margin-top: 34px;
}

.css-xu01pc {
  display: block;
  background: none;
  padding: 0 10px 8px 0px;
  margin-top: 9px;
  border-width: 0;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  height: 27px;
}

.css-xu01pc:focus,
.css-xu01pc:focus-visible {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.css-2jfdz5::after {
  content: " Three Thousand only";
  max-width: 200px;
  font-size: 12px;
  margin: -5px 0px;
  text-align: right;
  color: #333333;
  position: absolute;
  right: 0px;
  top: 0px;
}

.css-2jfdz5 .inputStyle.has-value ~ .inputLabelStyle {
  color: #7f7f7f;
  font-weight: 400;
}

input:focus ~ .css-wfstwc,
input.has-value ~ .css-wfstwc {
  font-size: 9px;
  top: -21px;
  left: 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
}

.css-gbfk11 {
  background-color: white;
  padding: 12px 16px;
  position: fixed;
  bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  left: 0px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 10px 0px;
  border-radius: 15px;
}

.css-82ihkd.full-width {
  width: 100%;
}

.css-82ihkd.default {
  font-size: 12px;
  line-height: 16px;
  color: #5e47dc;
  background-color: rgba(94, 71, 220, 0.1);
}

.css-82ihkd.primary {
  font-size: 14px;
  line-height: 20px;
  color: white;
  background-color: #55d6a7;
  border-bottom: 3px solid #4bba92;
}

.css-g9xpsd .option.active {
  background-color: #f2f3f8;
}

.css-g9xpsd .option.active g {
  fill: #2f116d;
  color: #2f116d;
}

.css-g9xpsd .option.active path {
  fill: #2f116d;
  color: #2f116d;
}

.css-82ihkd {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  padding: 0px 15px;
  border: none;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
}

.css-alals0::after {
  content: "";
  max-width: 200px;
  font-size: 12px;
  margin: -5px 0px;
  text-align: right;
  color: #333333;
  position: absolute;
  right: 0px;
  top: 0px;
}

.otp-boxes input:focus {
  border: 1px solid #d8d8d8;
}

.otp-container {
  margin-top: 50px;
}

.otp-boxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.otp-container .otp-boxes .otp-box {
  border-bottom-color: #aaa;
  color: #222;
  font-size: 55px;
  width: 60px;
}

.otp-boxes .otp-box.layout-2 {
  border-left: none;
  border-radius: 0;
  border-right: none;
  border-top: none;
}

.otp-boxes .otp-box:not(:last-child) {
  margin-right: 10px;
}

.otp-boxes .otp-box {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  font-size: 1.143em;
  height: 54px;
  text-align: center;
  width: 58px;
}

.otp-container .otp-actions {
  margin-top: 35px;
}

.otp-container .otp-actions p {
  margin-bottom: 5px;
}

.otp-container .otp-actions .clock-icon {
  margin: 0 5px;
  width: 20px;
}

.otp-container .otp-actions p,
.otp-container .otp-actions span {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 21px;
}

.otp-container .otp-actions .call-otp {
  margin-top: 20px;
}

.otp-container .otp-actions .call-otp,
.otp-container .otp-actions .resend-otp {
  color: #1728e7;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.otp-container .otp-actions p,
.otp-container .otp-actions span {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #666;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 21px;
}

.main-container .main-content.color-banner {
  background: #fff;
  border-radius: 15px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
}

.login-wrapper .form-section .input-fields .input-container p {
  line-height: 20px;
  text-transform: capitalize;
}

.jNKTSn {
  color: #222222;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0px 0px 6px 10px;
}

.gjZEHA {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f9f9fc;
  border-width: 1px;
  border-style: solid;
  border-color: #d8d8d8;
  border-radius: 10px;
  color: #222222;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 14px;
  padding: 14px 0px 14px 13px;
  width: 100%;
}

.categorydetails-page .desktop-operators-main-header,
.locationdetails-page .desktop-operators-main-header {
  margin: 15px 0 25px;
  position: relative;
}


.categorydetails-page .desktop-operators-main-header .desktop-heading-image,
.locationdetails-page .desktop-operators-main-header .desktop-heading-image {
  float: left;
  height: 45px;
  margin-right: 15px;
  margin-top: 5px;
}

.categorydetails-page
  .desktop-operators-main-header
  .desktop-heading-image
  img.heading-image,
.locationdetails-page
  .desktop-operators-main-header
  .desktop-heading-image
  img.heading-image {
  max-height: 45px;
  max-width: 45px;
}

.categorydetails-page .desktop-operators-main-header .main-heading,
.locationdetails-page .desktop-operators-main-header .main-heading {
  color: #222;
  font-family: sspLight;
  font-size: 1.286em;
  font-weight: 300;
  line-height: 24px;
}

.categorydetails-page .desktop-operators-main-header .sub-heading,
.locationdetails-page .desktop-operators-main-header .sub-heading {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.286em;
  line-height: 28px;
}

.categorydetails-page .desktop-operators-main-header img:not(.heading-image),
.locationdetails-page .desktop-operators-main-header img:not(.heading-image) {
  max-height: 210px;
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
}

.categorydetails-page form,
.locationdetails-page form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 250px;
  padding: 20px 24px;
}

.categorydetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .accountdetails-field,
.locationdetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .accountdetails-field {
  margin-bottom: 5px;
}

.categorydetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .accountdetails-field.error
  + .field-description,
.categorydetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .select-popup-wrapper.error
  + .field-description,
.locationdetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .accountdetails-field.error
  + .field-description,
.locationdetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .select-popup-wrapper.error
  + .field-description {
  color: red;
}

.categorydetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .field-description,
.locationdetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .field-description {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  line-height: 15px;
  margin-bottom: 20px;
}

.kjqNrV {
  position: relative;
}

.dMWSZh {
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 14px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
}

.iKzHuO {
  color: white;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6693f),
    to(#eb8345)
  );
  background: linear-gradient(to right, #f6693f 0%, #eb8345 100%);
  border: none;
  border-radius: 10px;
  font-family: sspBold;
  font-size: 16px;
  height: 54px;
}

.about-section {
  color: #888;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 22px;
}

.about-section h1 {
  color: #444;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  font-weight: 600;
  line-height: 1.8;
  text-transform: capitalize;
}

.about-section p:not(:first-child) {
  margin-top: 5px;
}

.about-section h1 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section li,
.about-section p {
  color: #888;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 22px;
}

.about-section h1:not(:first-child),
.about-section h2:not(:first-child),
.about-section h3:not(:first-child),
.about-section h4:not(:first-child) {
  margin-top: 30px;
}

.about-section h2,
.about-section h3,
.about-section h4 {
  color: #444;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  line-height: 1.5;
  font-weight: 600;
  text-transform: capitalize;
}

.about-section {
  background-color: #fff;
  border-radius: 15px;
}

.about-section .list-data {
  margin: 10px 0;
}

.about-section .list-item {
  margin: 20px 0;
}

.footer-section {
  background: #f6f8f9;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f6f8f9),
    color-stop(50%, #f4f6f7)
  );
  background: linear-gradient(180deg, #f6f8f9, #f4f6f7 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#ffffff",GradientType=1);
}

.footer-section .upper-section {
  padding: 25px 25px 10px;
}

.footer-section .upper-section .footer-list-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 15px;
}

.footer-section .upper-section .footer-list-container .footer-list:last-child {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
}

.footer-section .upper-section .footer-list-container .footer-list {
  width: 22%;
}

.footer-section .upper-section h3 {
  color: #444;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  line-height: 26px;
  margin-bottom: 5px;
}

.footer-section .upper-section li a,
.footer-section .upper-section li span {
  color: #5a6876;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 30px;
}

.footer-section
  .upper-section
  .footer-list-container
  .footer-list:last-child
  > .links-list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(max-content, 1fr)) [2];
  grid-template-columns: repeat(2, minmax(-webkit-max-content, 1fr));
  grid-template-columns: repeat(2, minmax(max-content, 1fr));
}

.footer-section
  .upper-section
  .footer-list-container
  .footer-list:last-child
  > .links-list
  h3 {
  grid-column-end: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
}

.footer-section .upper-section h3 {
  color: #444;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  line-height: 26px;
  margin-bottom: 5px;
}

.tooltip-wrapper {
  display: inline-block;
  position: relative;
}

.footer-section .upper-section li a,
.footer-section .upper-section li span {
  color: #5a6876;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 30px;
}

.tooltip-label.tooltip-show-arrow {
  padding-right: 15px;
}

.tooltip-label {
  cursor: pointer;
  position: relative;
}

.footer-section .upper-section .social-links .app-links,
.footer-section .upper-section .social-links .social-medias-links {
  margin-bottom: 15px;
}

.footer-section .upper-section .social-links .social-medias-links .images-list {
  margin-top: 10px;
}

.footer-section .upper-section .social-links .app-links,
.footer-section .upper-section .social-links .social-medias-links {
  margin-bottom: 15px;
}

.footer-section
  .upper-section
  .social-links
  .app-links
  .images-list
  .google-play {
  margin-right: 15px;
}

.footer-section .upper-section .social-links .app-links .images-list img {
  cursor: pointer;
}

.footer-section .upper-section .social-links .certified-image {
  border-top: 1px solid rgba(151, 151, 151, 0.16);
  padding-top: 15px;
  width: 100%;
}

.footer-section
  .upper-section
  .social-links
  .social-medias-links
  .images-list
  img:last-child {
  margin-right: 0;
}

.footer-section
  .upper-section
  .social-links
  .social-medias-links
  .images-list
  img {
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  width: 40px;
}

.footer-section .lower-section .text-content {
  color: #888;
  font-family: "Roboto", sans-serif;
  font-size: 0.714em;
  line-height: 20px;
  margin: auto;
  padding: 0 25px 10px;
}

.footer-section
  .upper-section
  .footer-list-container
  .footer-list:last-child
  > .links-list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(max-content, 1fr)) [2];
  grid-template-columns: repeat(2, minmax(-webkit-max-content, 1fr));
  grid-template-columns: repeat(2, minmax(max-content, 1fr));
}

.progress-bar-content {
  padding: 14px 20px 10px;
}

.progress-bar-content .progress-step-details {
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  margin: 0 0 10px 2px;
}

.progress-bar-content .progress-bar {
  background-color: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 15px;
  height: 6px;
}

.progress-bar-content .progress-bar .progress-bar-line {
  background-color: #fc7944;
  border-radius: 15px;
  height: 100%;
}

.main-container .main-content .heading-section {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 25px 20px 0;
}

.main-container .main-content .heading-section .heading-image {
  height: 36px;
  margin-right: 12px;
}

.main-container .main-content .heading-section .heading-content .heading-text {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  font-weight: 600;
}

.main-container
  .main-content
  .heading-section
  .heading-content
  .sub-heading-text {
  color: #666;
  font-size: 0.857em;
  line-height: 14px;
  margin-top: 7px;
}

.add-landlord-details-section,
.services-view-section {
  padding: 0 20px 20px;
}

.rentals-count-section {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff3d2;
  border-radius: 10px;
  color: #222;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.857em;
  line-height: 15px;
  margin: 20px 0;
  padding: 10px 15px;
}

.rentals-count-section img {
  width: 22px;
}

.rentals-count-section p {
  margin-left: 10px;
}

.add-landlord-details-form .form-fields,
.modify-landlord-details-form .form-fields,
.services-details-form .form-fields {
  margin-bottom: 95px;
}

.form-fields .input-wrapper,
.form-fields .pincode-city-container,
.form-fields .pincode-state-container,
.form-fields .rentals-tnc-checkbox,
.form-fields .select-popup-wrapper,
.form-fields .textarea-wrapper,
.form-fields .upload-file-container {
  margin-bottom: 30px;
}

.kjqNrV {
  position: relative;
}

.IbxLl {
  color: red;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0px 0px 6px 10px;
}

.hOoXYw {
  color: red;
  display: block;
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0px;
  text-transform: capitalize;
}

.iKzHuO {
  color: white;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6693f),
    to(#eb8345)
  );
  background: linear-gradient(to right, #f6693f 0%, #eb8345 100%);
  border: none;
  border-radius: 10px;
  font-family: sspBold;
  font-size: 16px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.iKzHuO:hover {
  color: #fff;
}

.form-btns.fixed-form-btn {
  background-color: #fff;
  border-top: 1px solid #d8d8d8;
  bottom: 0;
  left: 0;
  padding: 15px;
  position: fixed;
  right: 0;
}

.search-layout.new-layout {
  background-color: #fff;
}

.form-btns button {
  color: white;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6693f),
    to(#eb8345)
  );
  background: linear-gradient(to right, #f6693f 0%, #eb8345 100%);
  border: none;
  border-radius: 10px;
  font-family: sspBold;
  font-size: 16px;
  height: 54px;
}

.search-layout.new-layout {
  padding: 0;
}

.search-layout.new-layout .desktop-operators-main-header {
  margin: 15px 0 25px;
  position: relative;
}

.search-layout.new-layout
  .desktop-operators-main-header
  .desktop-heading-image {
  float: left;
  height: 45px;
  margin-right: 15px;
  margin-top: 5px;
}

.search-layout.new-layout
  .desktop-operators-main-header
  img:not(.heading-image) {
  max-height: 210px;
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
}

.search-layout.new-layout
  .desktop-operators-main-header
  .desktop-heading-image
  img.heading-image {
  max-height: 45px;
  max-width: 45px;
}

.search-layout.new-layout .desktop-operators-main-header .main-heading {
  color: #222;
  font-family: sspLight;
  font-size: 1.286em;
  font-weight: 300;
  line-height: 24px;
}

.search-layout.new-layout .desktop-operators-main-header .sub-heading {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.286em;
  line-height: 28px;
}

.search-layout.new-layout .search-layout-section.search-input-wrapper {
  padding: 10px 20px 25px 0;
}

.search-layout.new-layout .search-layout-section {
  margin: 5px 0 10px;
}

.search-layout .search-layout-section {
  margin-bottom: 10px;
}

.search-layout.new-layout
  .search-layout-section.search-input-wrapper
  .desktop-search-heading {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 20px;
  margin-bottom: 10px;
}

.search-layout.new-layout .search-layout-field-wrapper {
  background: #f9f9fc;
  border-radius: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  height: 48px;
  padding: 11px 15px;
}

.search-layout .search-layout-field-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding: 11px;
  width: 100%;
}

.search-layout.new-layout
  .search-layout-field-wrapper
  .search-layout-field-icon
  img {
  width: 18px;
}

.search-layout .search-layout-field-wrapper .search-layout-input-wrapper {
  width: 100%;
}

.search-layout.new-layout
  .search-layout-field-wrapper
  .search-layout-input-wrapper
  input {
  background: none;
  font-family: "Roboto", sans-serif;
  font-size: 1.286em;
  padding: 0 13px 0 0;
}

.search-layout .search-layout-field-wrapper .search-layout-input-wrapper input {
  border: 0;
  font-size: 1em;
  height: 100%;
  padding: 0 0 0 13px;
}

.ldAVAW {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: #e4e4e4;
  border-radius: 0px;
  color: #222222;
  font-size: 12px;
  line-height: 14px;
  padding: 14px 0px 14px 13px;
  width: 100%;
}

.search-layout .search-layout-collapsible-section {
  margin-top: 10px;
  max-height: 500px;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.search-layout.new-layout
  .search-layout-collapsible-section.collapsed
  .search-layout-section {
  margin: 0;
}

.search-layout.new-layout .search-layout-section {
  margin: 5px 0 10px;
}

.search-layout .search-layout-section {
  margin-bottom: 10px;
}

.slider-wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin: 5px;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.offers-slider-wrapper {
  margin: 0;
}

.slider-wrapper .slider-track .slider-section .slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  scrollbar-width: none;
  width: 100%;
}

.slider-wrapper .slider-track .slider-section .slider .slide:first-child {
  margin-left: 0 !important;
}

.slider-wrapper .slider-track .slider-section .slider .slide {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.offers-slider-wrapper .offers-slider .slide {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
}

.offers-card,
.offers-card .offers-image-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.offers-card {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border-radius: 12px;
  cursor: pointer;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 5px;
  padding: 20px;
}

.offers-card .offers-image-desc {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.offers-card,
.offers-card .offers-image-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.offers-card .img-div {
  -ms-flex-item-align: start;
  align-self: flex-start;
  height: 45px;
  margin: 3px 15px 0 0;
  max-width: 45px;
}

.offers-card .img-div img {
  height: 100%;
}

.offers-card .desc-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: calc(100% - 60px);
}

.offers-card .desc-div .desc-head {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 18px;
  margin-bottom: 5px;
}

.offers-card .desc-div .desc-text {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  line-height: 16px;
}

.location-info-box {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fffcfa;
  border: 1px solid #ffd0b5;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 10px;
  padding: 10px 16px;
  margin-right: 14px !important;
}

.location-info-box .cta {
  color: #e85a1c;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  margin-left: 26px;
}

.location-info-box .heading {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
}

.location-info-box .desc {
  color: #666;
  font-size: 0.857em;
  line-height: 16px;
}

.search-layout.new-layout .search-layout-section {
  margin: 5px 0 10px;
}

.search-layout .search-layout-section {
  margin-bottom: 10px;
}

.search-layout.new-layout .search-layout-section .section-head {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  margin-bottom: 0;
  padding-bottom: 30px;
  text-transform: none;
}

.search-layout .search-layout-section .section-head {
  color: #5c5c5c;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.search-layout.new-layout .search-layout-section .group-head {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 0.714em;
  line-height: 25px;
  text-transform: capitalize;
}

.search-layout.new-layout
  .search-layout-section
  .search-tile-wrapper:nth-of-type(2)
  .group-head {
  margin-top: 0;
}

.search-layout.new-layout .grouped-operator-tiles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  word-break: break-word;
}

.search-layout.new-layout .operator-tile {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background: #fff;
  border-radius: 12px;
  cursor: pointer;
  margin-bottom: 15px;
  min-height: 81px;
  min-width: 170px;
  padding: 18px;
  word-break: break-word;
}

.search-layout .operator-tile {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
}

.search-layout.new-layout .operator-tile.flex-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-layout .operator-tile .logo-container {
  position: relative;
}

.search-layout.new-layout .operator-tile .operator-img {
  border-radius: 50%;
  height: auto;
  max-height: 45px;
  width: 45px;
  margin-right: 14px;
}

.search-layout .operator-tile .operator-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 1.3;
}

.search-layout.new-layout .operator-tile .operator-content .operator-name {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
}

.search-layout .operator-tile .operator-tag {
  background-color: #f74;
  border-radius: 10px;
  color: #fff;
  font-size: 0.571em;
  padding: 2px 4px;
  position: absolute;
  right: 8px;
  top: 0;
}

.providerdetails-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 250px;
  padding: 26px 12px;
}

.providerdetails-page .desktop-operators-main-header {
  margin: 15px 0 45px;
  position: relative;
}

.providerdetails-page .desktop-operators-main-header .main-heading {
  color: #222;
  font-family: sspLight;
  font-size: 1.286em;
  font-weight: 300;
  line-height: 24px;
}

.providerdetails-page .desktop-operators-main-header .sub-heading {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.286em;
  line-height: 28px;
}

.providerdetails-page .desktop-operators-main-header img.illustrator {
  max-height: 210px;
  position: absolute;
  right: 0;
  top: 70px;
  width: 230px;
}

.providerdetails-page form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.providerdetails-page form .accountdetails-head {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 5px;
  position: relative;
}

.providerdetails-page form .accountdetails-head .operator-details {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.providerdetails-page
  form
  .accountdetails-head
  .operator-details
  .operator-details-container {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.providerdetails-page
  form
  .accountdetails-head
  .operator-details
  .operator-img {
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 50%;
  height: 50px;
  margin-right: 10px;
  width: 50px;
}

.providerdetails-page
  form
  .accountdetails-head
  .operator-details
  .operator-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.providerdetails-page
  form
  .accountdetails-head
  .operator-details
  .operator-content
  .operator-code {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  line-height: 20px;
  margin-bottom: 5px;
  word-break: break-word;
}

.providerdetails-page .operator-edit,
.reviewbill-page .operator-edit {
  color: #f74;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  margin-left: 10px;
  text-decoration: none;
}

.providerdetails-page form .accountdetails-head {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 5px;
  position: relative;
}

.providerdetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .accountdetails-field {
  margin-bottom: 5px;
}

.providerdetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .accountdetails-field.error
  + .field-description {
  color: red;
}

.providerdetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .field-description {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  line-height: 15px;
  margin-bottom: 20px;
}

.providerdetails-page
  form
  .accountdetails-form
  .accountdetails-field-wrapper
  .accountdetails-field
  input {
  font-size: 1.286em;
  height: 50px;
  text-transform: uppercase;
}

.button-wrapper button {
  color: white;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6693f),
    to(#eb8345)
  );
  background: linear-gradient(to right, #f6693f 0%, #eb8345 100%);
  border: none;
  border-radius: 10px;
  font-family: sspBold;
  font-size: 16px;
  height: 54px;
}

.about-section h1:not(:first-child),
.about-section h2:not(:first-child),
.about-section h3:not(:first-child),
.about-section h4:not(:first-child) {
  margin-top: 30px;
}

.about-section h2,
.about-section h3,
.about-section h4 {
  color: #444;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  line-height: 1.5;
  text-transform: capitalize;
}

.about-section a {
  color: #464646;
}

a,
a:active,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
}

.providerdetails-page .desktop-operators-main-header img.bbps-img {
  height: 32px;
  position: absolute;
  right: 0;
  top: 0;
  width: 32px;
}

.about-section p:not(:first-child) {
  margin-top: 5px;
}

.about-section h1 ~ p {
  margin-top: 5px;
  white-space: normal;
}

.about-section li,
.about-section p {
  color: #888;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 22px;
  list-style: auto;
}

.about-section h1 ~ ol {
  list-style-type: value;
  padding: 3px 0 0 18px;
}

.about-section .utility-table {
  border: 1px solid #b0b0b0;
  margin: 23px 0 10px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.about-section .utility-table tr:first-child {
  background-color: #f5f5f5;
  border: none;
}

.about-section .utility-table tr {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

.about-section .utility-table tr:first-child td {
  border: none;
  font-family: sspLight;
}

.about-section .utility-table td {
  border: 1px solid #b0b0b0;
  padding: 10px;
}

.gplay-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gplay-container .desktop-gplay-main-header {
  margin: 15px 0 40px;
  position: relative;
}

.gplay-container .desktop-gplay-main-header .desktop-heading-image {
  float: left;
  margin-right: 15px;
  margin-top: 5px;
}

.gplay-container
  .desktop-gplay-main-header
  .desktop-heading-image
  img.heading-image {
  max-height: 45px;
  max-width: 45px;
}

.gplay-container .desktop-gplay-main-header .main-heading {
  color: #222;
  font-family: sspLight;
  font-size: 1.286em;
  font-weight: 300;
  line-height: 28px;
}

.gplay-container .desktop-gplay-main-header .sub-heading {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.286em;
}

.gplay-container .desktop-gplay-main-header img.illustrator {
  max-height: 190px;
  position: absolute;
  right: 0;
  top: 30px;
  width: 210px;
}

.gplay-container.new-layout .amount-layout-section {
  margin: 5px 0 10px;
}

.gplay-container.new-layout
  .amount-layout-section.amount-input-wrapper
  .amount-layout-flex-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.gplay-container.new-layout
  .amount-layout-section.amount-input-wrapper
  .amount-layout-flex-wrapper
  .amount-input-field-wrapper {
  -ms-flex-preferred-size: 45%;
  flex-basis: 45%;
}

.gplay-container.new-layout
  .amount-layout-section.amount-input-wrapper
  .amt-label {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  padding-bottom: 10px;
}

.gplay-container.new-layout .button-wrapper {
  padding-bottom: 38px;
}

.gplay-container .choose-amt-div {
  border-radius: 2px;
}

.gplay-container .choose-amt-div .choose-amt-label {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  padding-top: 10px;
}

.gplay-container .choose-amt-div .rounded-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
}

.gplay-container .choose-amt-div .rounded-list .list-item {
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-right: 10px;
  padding: 10px;
  width: 100%;
}

.gplay-container .choose-amt-div .rounded-list .list-item p {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
}

.gplay-container .benefits-container {
  margin: 0;
}

.gplay-container .benefits-container .benefits-div {
  background-color: #fff;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 9px 0 RGB(0 0 0/5%);
  box-shadow: 0 0 9px 0 RGB(0 0 0/5%);
  margin-top: 10px;
  padding: 25px 5px 25px 23px;
  border: 2px solid #dddddd54;
}

.gplay-container .benefits-container .benefits-div .benefits-label {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
}

.gplay-container
  .benefits-container
  .benefits-div
  .benefits-div-grid
  .benefit-item
  img {
  height: 36px;
  margin-right: 20px;
}

.gplay-container
  .benefits-container
  .benefits-div
  .benefits-div-grid
  .benefit-item
  .benefit-text-section
  .benefit-head {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  margin-bottom: 6px;
}

.gplay-container
  .benefits-container
  .benefits-div
  .benefits-div-grid
  .benefit-item
  .benefit-text-section
  .benefit-text {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 18px;
}

.gplay-container .tnc-container .tnc-div {
  background-color: #fff;
  border-radius: 20px;
  -webkit-box-shadow: 0 0 9px 0 RGB(0 0 0/5%);
  box-shadow: 0 0 9px 0 RGB(0 0 0/5%);
  margin: 20px 0 15px;
  padding: 30px 20px 20px;
  border: 2px solid #dddddd54;
}

.gplay-container .tnc-container .tnc-div .tnc-label {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  margin-bottom: 10px;
}

.gplay-container .tnc-container .tnc-div .gPlay-tnc-data {
  cursor: pointer;
  position: relative;
}

.gplay-container .tnc-container .tnc-div .gPlay-tnc-data .gPlay-tnc-text {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  line-height: 25px;
  overflow: hidden;
  position: relative;
  -webkit-transition: max-height 0.5s ease-in-out 0s;
  transition: max-height 0.5s ease-in-out 0s;
}

.gplay-container .tnc-container .tnc-div .gPlay-tnc-data .read-more-overlay {
  bottom: 20px;
  height: 5rem;
  left: 0;
  position: absolute;
  right: 0;
  background: rgba(255, 255, 255, 0);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(255, 255, 255, 0)),
    color-stop(30%, rgba(255, 255, 255, 0)),
    color-stop(80%, #fff),
    to(#fff)
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0) 30%,
    #fff 80%,
    #fff
  );
}

.gplay-container
  .tnc-container
  .tnc-div
  .gPlay-tnc-data
  .gPlay-tnc-text-read-more-toggle {
  color: #e3714d;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  height: 1rem;
  margin-top: 10px;
}

.gPlay-tnc-text.active {
  max-height: 200px !important;
  -webkit-transition: max-height 0.5s ease-in-out 0s;
  transition: max-height 0.5s ease-in-out 0s;
}

.primary-list ul li:nth-child(2) {
  display: none;
}

.primary-list ul li:nth-child(3) {
  display: none;
}

.primary-list ul li:nth-child(4) {
  display: none;
}

.primary-list ul li:nth-child(5) {
  display: none;
}

.billdetails-page {
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.billdetails-page .billdetails-wrapper {
  background: #fff;
  min-height: inherit;
  padding: 20px 0;
  position: relative;
}

.billdetails-page .billdetails-wrapper .billdetails-head {
  border-bottom: 2px solid #f4f6f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 0 20px 20px;
  position: relative;
}

.billdetails-page .billdetails-wrapper .desktop-operators-main-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}

.billdetails-page
  .billdetails-wrapper
  .desktop-operators-main-header
  .desktop-header-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.billdetails-page
  .billdetails-wrapper
  .desktop-operators-main-header
  .main-heading {
  color: #666;
  font-family: sspLight;
  font-size: 1.286em;
  font-weight: 300;
  line-height: 28px;
}

.billdetails-page
  .billdetails-wrapper
  .desktop-operators-main-header
  .sub-heading {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.286em;
}

.billdetails-page
  .billdetails-wrapper
  .billdetails-head
  .operators-details-edit {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.billdetails-page .billdetails-wrapper .billdetails-head .operator-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.billdetails-page
  .billdetails-wrapper
  .billdetails-head
  .operator-details
  .operator-img {
  -ms-flex-item-align: start;
  align-self: flex-start;
  border-radius: 50%;
  height: 50px;
  margin-right: 10px;
  width: 50px;
}

.billdetails-page
  .billdetails-wrapper
  .billdetails-head
  .operator-details
  .operator-info-block {
  width: 100%;
}

.billdetails-page
  .billdetails-wrapper
  .billdetails-head
  .operator-details
  .operator-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 8px;
}

.billdetails-page
  .billdetails-wrapper
  .billdetails-head
  .operator-details
  .operator-content
  .operator-billNumber {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  line-height: 22px;
  text-transform: uppercase;
  word-break: break-word;
  font-weight: 600;
}

.billdetails-page
  .billdetails-wrapper
  .billdetails-head
  .operator-details
  .operator-content
  .operator-code {
  color: #666;
  font-size: 0.857em;
  line-height: 16px;
  margin-bottom: 5px;
  margin-right: 20px;
  word-break: break-word;
}

.billdetails-page .operator-edit,
.billdetails-page .rounded-input-partial-amount .remove-partial-field {
  color: #f74;
  cursor: pointer;
  font-size: 1em;
}

.billdetails-page .operator-edit,
.billdetails-page .rounded-input-partial-amount .remove-partial-field {
  color: #f74;
  cursor: pointer;
  font-size: 1em;
}

.billdetails-page
  .billdetails-wrapper
  .billdetails-head
  .operator-details
  .operator-info-block
  .separator {
  border-bottom: 1px solid rgba(151, 151, 151, 0.23);
  height: 1px;
  margin: 14px 0;
}

.billdetails-page
  .billdetails-wrapper
  .billdetails-head
  .operator-details
  .operator-info-block
  .user-details {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  line-height: 16px;
  margin-bottom: 5px;
  margin-right: 20px;
  margin-top: 10px;
}

.billdetails-page .billdetails-wrapper .bill-details-content {
  padding: 0 20px;
}

.billdetails-page
  .billdetails-wrapper
  .bill-details-content
  .bill-details-head-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}

.billdetails-page
  .billdetails-wrapper
  .bill-details-content
  .bill-details-head {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  font-weight: 600;
}

.billdetails-page .bbps-img {
  height: 32px;
  width: 32px;
}

.billdetails-page .billdetails-wrapper .bill-details-content .bill-fields-area {
  background: #fafbff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 22px 20px 20px;
}

.billdetails-page .billdetails-wrapper .bill-amount-date {
  border-bottom: 1px solid #d8d8d8;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 10px;
}

.billdetails-page .billdetails-wrapper .bill-field {
  margin-bottom: 15px;
}

.billdetails-page .billdetails-wrapper .bill-field .bill-field-title {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  margin-bottom: 5px;
}

.billdetails-page .billdetails-wrapper .bill-field .bill-field-value {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.286em;
  word-break: break-word;
  font-weight: 600;
}

.billdetails-page
  .billdetails-wrapper
  .bill-field
  .bill-field-value.amount:before {
  color: #5c5c5c;
  content: "\20B9 ";
  font-size: 12px;
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
}

.billdetails-page .billdetails-wrapper .details-popup-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 15px;
}

.billdetails-page .billdetails-wrapper .details-popup-wrapper .bill-field {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}

.billdetails-page
  .billdetails-wrapper
  .details-popup-wrapper
  .bill-field
  .bill-field-title,
.billdetails-page
  .billdetails-wrapper
  .details-popup-wrapper
  .bill-field
  .bill-field-value {
  font-size: 1em;
  margin: 0 10px 0 0;
}

.billdetails-page
  .billdetails-wrapper
  .bill-details-content
  .open-details-popup {
  color: #f74;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
}

.billdetails-page
  .billdetails-wrapper
  .bill-details-content
  .open-details-popup:after {
  background: url(https://pwa-cdn.freecharge.in/pwa-static/pwa/images/icons/details-chevron-down.svg)
    50% no-repeat;
  content: "";
  display: inline-block;
  height: 20px;
  vertical-align: middle;
  width: 20px;
}

.pop-up-wrapper.top-rounded-layout .pop-up .close-pop-up-icon {
  width: 24px;
}

.billdetails-page .note-info {
  background-color: #f0f5fc;
  border-radius: 12px;
  font-size: 0.857em;
  margin-bottom: 14px;
  padding: 10px;
}

.billdetails-page .note-info .note-info-heading {
  color: #222;
  font-family: "Roboto", sans-serif;
  margin-bottom: 6px;
}

.billdetails-page .note-info .note-info-heading img {
  margin-right: 4px;
  width: 12px;
}

.billdetails-page .note-info .note-info-text {
  color: #666;
  line-height: 14px;
}

.billdetails-page .footer-content {
  padding: 20px;
}

.billdetails-page .proceed-btn {
  font-size: 1.143em;
  height: 50px;
  text-transform: uppercase;
  width: 100%;
}

.billdetails-page .partial-pay-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.billdetails-page .rounded-input-partial-amount {
  margin-right: 20px;
  width: 250px;
}

.billdetails-page .rounded-input-partial-amount span {
  font-size: 1.286em;
}

.klhhjU {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  line-height: 14px;
  left: calc(11px);
  padding: 14px 0px;
  position: absolute;
  top: 0px;
}

.billdetails-page .rounded-input-partial-amount input {
  font-size: 1.286em;
  height: 50px;
  padding: 13px 72px 14px 25px;
}

.fpFLnk {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f9f9fc;
  border-width: 1px;
  border-style: solid;
  border-color: #d8d8d8;
  border-radius: 10px;
  color: #222222;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 14px;
  padding: 14px 55px 14px 20px;
  width: 100%;
}

.fpFLnk:focus {
  border-width: 1px;
  border-style: solid;
  border-color: #d8d8d8;
}

.billdetails-page .rounded-input-partial-amount p {
  top: 18px;
}

.bSDmYT {
  position: absolute;
  right: 15px;
  top: 14px;
}

.billdetails-page .rounded-input-partial-amount .field-description {
  color: #666;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  line-height: 15px;
  margin-top: 5px;
}

.billdetails-page .billdetails-wrapper .bill-field .bill-edit {
  color: #f74;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  margin-top: 18px;
}

.rounded-input-partial-amount {
  display: none;
}

.rounded-input-partial-amount.active {
  display: block;
}

.pop-up-wrapper.show-pop-up {
  bottom: 0;
  top: 0;
}

.pop-up-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  bottom: -120%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 4;
}

.pop-up-wrapper.top-rounded-layout .pop-up {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.pop-up-wrapper .pop-up {
  background: #fff;
  max-height: 500px;
  overflow: auto;
  position: relative;
  scrollbar-width: none;
  -webkit-transition: -webkit-transform 0.2s ease-out 0s;
  transition: -webkit-transform 0.2s ease-out 0s;
  transition: transform 0.2s ease-out 0s;
  transition: transform 0.2s ease-out 0s, -webkit-transform 0.2s ease-out 0s;
}

.billdetails-page .billdetails-wrapper .bill-details-popup {
  max-height: 500px;
  padding: 30px;
}

.billdetails-page .billdetails-wrapper .bill-details-popup .bill-popup-heading {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  margin-bottom: 40px;
}

.billdetails-page
  .billdetails-wrapper
  .bill-details-popup
  .bill-field:nth-of-type(2n) {
  margin-right: 0px;
}

.billdetails-page .billdetails-wrapper .bill-details-popup .bill-field {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 30px;
  width: calc(50% - 5px);
}

.payment-section-wrapper {
  background-color: #f4f6f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: calc(100vh - 62px);
}

.payment-section-wrapper .pay-option-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 95px;
}

.payment-section-wrapper .first-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  position: relative;
}

.payment-section-wrapper .payment-options-section {
  overflow: hidden;
}

.tabs-collection {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  z-index: 1;
}

.payment-modes .payment-options-container.tabs-collection .tab-option {
  border-bottom: 2px solid transparent;
  font-size: 0.857em;
  margin: 0 20px 0 0;
  padding-bottom: 5px;
  text-transform: capitalize;
  white-space: nowrap;
}

.payment-modes
  .payment-options-container.tabs-collection
  .tab-option:last-child {
  margin-right: 0;
}

.tabs-collection .tab-option.active {
  border-bottom: 2px solid #e3714d;
  color: #464646;
  font-family: "Roboto", sans-serif;
}

.tabs-collection .tab-option,
.tabs-collection .tab-option.active {
  -webkit-transition: border-bottom 0.3s cubic-bezier(1, 1.01, 1, 1) 0s;
  transition: border-bottom 0.3s cubic-bezier(1, 1.01, 1, 1) 0s;
}

.tabs-collection .border-under-text {
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 0;
  padding-right: 0;
}

.tabs-collection .tab-option {
  border-bottom: 2px solid #f0f4f5;
  color: #9c9c9c;
  cursor: pointer;
  font-size: 13px;
  font-weight: 300;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.payment-modes .debit-credit-card-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.payment-modes .debit-credit-card-section .new-card-container {
  display: block;
  padding: 0;
}

.ddHRUi {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 7px;
}

.payment-modes .debit-credit-card-section .new-card-container > label {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  border-radius: 20px;
  margin-right: 0;
  -webkit-box-shadow: 0 1px 10px 0 RGB(0 0 0/6%);
  box-shadow: 0 1px 10px 0 RGB(0 0 0/6%);
}

.inEpIw:last-child {
  margin-right: 0px;
}

.inEpIw {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #5c5c5c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin-right: 45px;
  opacity: 1;
  position: relative;
  cursor: pointer;
}

.kMNlGX {
  cursor: pointer;
  position: absolute;
  margin: 0;
  opacity: 0;
}

.payment-modes
  .debit-credit-card-section
  .new-card-container
  > label
  .custom-icon {
  margin-left: 15px;
  margin-right: 0;
  margin-top: 25px;
}

.egKDDx {
  margin-right: 7px;
  width: 20px;
}

.payment-modes
  .debit-credit-card-section
  .new-card-container
  > label
  .label-text {
  display: block;
  width: 100%;
}

.payment-modes .add-card-strip.content-visible {
  border-radius: 20px;
  padding-bottom: 0;
}

.payment-modes .add-card-strip {
  margin: 0;
  padding: 22px 15px;
}

.payment-modes .add-card-strip,
.payment-modes .saved-card-strip {
  background-color: #fff;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 22px 15px;
  position: relative;
}

.payment-modes .add-card-strip .add-card-title {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  margin-top: 3px;
  width: 150px;
}

.payment-modes .add-card-strip .add-card-expanded.is-active {
  max-height: 600px;
}

.payment-modes .add-card-strip .add-card-expanded {
  max-height: 0;
  -webkit-transition: max-height 0.5s linear;
  transition: max-height 0.5s linear;
}

.payment-modes .add-card-strip .add-card-content {
  background-color: #f9f9fc;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin: 18px -15px 0 -50px;
  padding: 32px 25px;
}

.payment-modes .debit-credit-card-section .card-number {
  margin-bottom: 23px;
  position: relative;
}

.payment-modes
  .add-card-strip
  .add-card-content
  .input-label-without-animation {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
}

.payment-modes .debit-credit-card-section .card-number-container input,
.payment-modes .debit-credit-card-section .cvv-container input,
.payment-modes .debit-credit-card-section .expiry-date-container input {
  background-color: #fff;
  border: 1px solid transparent;
  font-size: 1em;
  letter-spacing: 1px;
}

.payment-modes .debit-credit-card-section .card-number .card-brand-container {
  height: 18px;
  max-width: 40px;
  min-width: 40px;
  overflow: hidden;
  position: absolute;
  right: 10px;
  top: 34px;
}

.payment-modes
  .debit-credit-card-section
  .card-number
  .card-brand-container
  img {
  -webkit-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  width: 100%;
}

.payment-modes .debit-credit-card-section .card-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
}

.payment-modes .debit-credit-card-section .card-detail .card-validity {
  max-width: 122px;
  width: 100%;
}

.payment-modes .debit-credit-card-section .card-detail .card-cvv {
  max-width: 142px;
  width: 100%;
}

.payment-modes .debit-credit-card-section .cvv-container {
  margin-left: 15px;
}

.payment-modes .add-card-strip .notification-section {
  max-height: 0;
  -webkit-transition: max-height 0.3s ease-in;
  transition: max-height 0.3s ease-in;
}

.payment-modes .debit-credit-card-section .card-info {
  font-size: 0.857em;
  line-height: 16px;
  margin-top: 20px;
}

.payment-modes .debit-credit-card-section .card-info .know-more {
  color: #f74;
  font-family: "Roboto", sans-serif;
}

.payment-section-wrapper .payment-options-section .submit-payment-option-btn {
  text-transform: uppercase;
}

.payment-modes .submit-payment-option-btn {
  text-transform: uppercase;
}

.hyYcxz {
  color: white;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: #aeaeae;
  border: none;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  height: 54px;
}

.payment-modes .options-disabled-info {
  color: #5e5e5e;
  font-size: 0.857em;
  line-height: 16px;
  margin: 15px 15px 5px;
}

.gjZEHA:focus {
  background-color: white;
  border-color: #ec7f51;
  -webkit-box-shadow: RGBA(239 209 197) 0px 1px 6px;
  box-shadow: RGBA(239 209 197) 0px 1px 6px;
}

.payment-modes .net-banking-section {
  margin-top: 16px;
}

.payment-modes .card-content-title {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  margin-bottom: 30px;
  padding-bottom: 16px;
  position: relative;
}

.payment-modes .card-content-title:before {
  border-top: 3px solid #e3714e;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  width: 25px;
}

.payment-modes .net-banking-section .netbanking-container {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
}

.ddHRUi {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 7px;
}

.payment-modes .net-banking-section .netbanking-container > label {
  border-radius: 20px;
  color: #222;
  height: 67px;
  line-height: 18px;
  margin-bottom: 15px;
  margin-right: 0;
  padding: 22px 15px;
  width: 47%;
  -webkit-box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.06);
}

.payment-modes .net-banking-section .netbanking-container > label .custom-icon {
  margin-right: 9px;
}

.payment-modes .net-banking-section .netbanking-container > label .label-text {
  display: block;
  width: 100%;
}

.payment-modes
  .net-banking-section
  .netbanking-container
  > label
  .bank-details {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.payment-modes
  .net-banking-section
  .netbanking-container
  > label
  .bank-details
  .bank-image {
  margin-right: 7px;
  width: 25px;
}

.payment-modes .net-banking-section .net-banking-view-all {
  margin: 15px 0 5px;
  text-align: center;
}

.payment-modes .net-banking-section .net-banking-view-all .view-more {
  color: #e3714e;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
}

.payment-section-wrapper .second-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.payment-section-wrapper .payment-head-section {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 0;
  margin-bottom: 5px;
  margin-top: -1px;
  min-height: 138px;
  padding: 20px;
  position: relative;
  -webkit-box-shadow: 0 0 23px 0 rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 23px 0 rgba(255, 255, 255, 0.5);
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section.layout-4 {
  background: #fff;
  color: #222;
  padding: 0;
}

.payment-section-wrapper .payment-head-section .payment-details-section {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 20px 30px 25px;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data {
  margin-bottom: 8px;
  width: 100%;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .total-amount-label {
  color: #666;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  margin-bottom: 5px;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .payment-details-first-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 25px;
  width: 100%;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .payment-details-first-row
  .service-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .payment-details-first-row
  .service-name
  span {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.95em;
  font-weight: 600;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .payment-details-first-row
  .service-name
  span:before {
  color: #222;
  content: "\20B9 ";
  font-size: "inherit";
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .payment-details-first-row
  .action {
  -ms-flex-line-pack: center;
  align-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: 10px;
  position: relative;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .payment-details-first-row
  .action:before {
  border-left: 1px solid #e4e4e4;
  bottom: 5px;
  content: "";
  height: 16px;
  left: 0;
  position: absolute;
  top: 6px;
}

.payment-section-wrapper
  .deal-details-section
  .payment-details-first-row
  .action
  .change-btn,
.payment-section-wrapper
  .payment-head-section
  .payment-details-data
  .payment-details-first-row
  .action
  .change-btn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  color: #f6693f;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 0.857em;
  margin-left: 11px;
}

.payment-section-wrapper .payment-head-section .payment-details-bbps {
  position: absolute;
  right: 35px;
  top: 22px;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-bbps
  .avatar-img {
  width: 60px;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section.layout-4
  .payment-details-second-row {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-avatar {
  margin-right: 16px;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-avatar
  .avatar-img {
  border-radius: 50%;
  height: 42px;
  width: 42px;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .payment-details-second-row
  .second-row-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 30px;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .payment-details-second-row
  .second-row-content
  label {
  color: #666;
  display: inline-block;
  font-size: 0.857em;
  line-height: 15px;
  margin-bottom: 5px;
}

.payment-section-wrapper
  .payment-head-section
  .payment-details-section
  .payment-details-data
  .payment-details-second-row
  .second-row-content
  span {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 1.143em;
  line-height: 19px;
  margin-bottom: 10px;
}

.payment-section-wrapper .payment-details-wrap {
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: 20px;
  padding: 35px 39px;
  -webkit-box-shadow: 0 0 23px 0 rgba(235, 235, 235, 0.53);
  box-shadow: 0 0 23px 0 rgba(235, 235, 235, 0.53);
}

.promo-code-section {
  border-radius: 15px;
  -webkit-box-shadow: 0 1px 5px 0 #d8d8d8;
  box-shadow: 0 1px 5px 0 #d8d8d8;
}

.promo-code-section input {
  border: none;
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  padding: 20px 15px 20px 49px;
}

.dkLYmB {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f9f9fc;
  border-width: 1px;
  border-style: solid;
  border-color: #d8d8d8;
  border-radius: 10px;
  color: #222222;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 14px;
  padding: 14px 0px 14px 43px;
  width: 100%;
}

.promo-code-section .input-container img {
  top: 24px;
  width: 24px;
}

.jDbFPk {
  position: absolute;
  left: 12px;
  top: 15px;
  width: 20px;
  height: 14px;
}

.promo-code-section .input-container p {
  top: 22px;
}

.jLNRYi {
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 17px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
}

.dkLYmB:focus {
  background-color: white;
  border-color: #ec7f51;
  -webkit-box-shadow: #efd1c5 0px 1px 6px;
  box-shadow: #efd1c5 0px 1px 6px;
}

.promo-code-section .dashed-line {
  display: none;
}

.payment-section-wrapper .amount-details h4 {
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.143em;
  font-weight: 600;
  margin-bottom: 12px;
}

.payment-section-wrapper .amount-details > div {
  color: #222;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-size: 1em;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 10px 0;
}

.payment-section-wrapper .amount-details > div > .breakup-value:before {
  color: #222;
  content: "\20B9 ";
  font-size: 1em;
}

.payment-section-wrapper .amount-details .total {
  border-top: 1px solid #e1d9d9;
  color: #222;
  font-family: "Roboto", sans-serif;
  font-size: 1.571em;
  margin-top: 22px;
  padding: 20px 0 0;
}

.payment-section-wrapper .amount-details .total span:before {
  color: #222;
  content: "\20B9 ";
  font-size: 1em;
}

#radio-two {
  display: none;
}

#radio-two.show {
  display: block !important;
}

#radio-one.none {
  display: none !important;
}

.vertical-tab-container .tabs-collection .tab-option .tab-icon i {
  color: #1728e7;
  font-size: 24px;
}

.Tabcontent-bill {
  display: none !important;
}

.Tabcontent-bill.current {
  display: block !important;
}

.main-navigation {
  display: none;
}

.primary-proceed {
  font-size: 14px;
  line-height: 20px;
  color: white;
  background-color: #55d6a7;
  border-bottom: 3px solid #4bba92;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 48px;
  padding: 0px 15px;
  border: none;
  font-weight: 500;
  border-radius: 10px;
}

.primary-proceed:hover {
  color: #fff;
}

